.page-container {
    width: 100%;
    height: auto;
}

.pdf-tab-title {
    padding: 0 15px;
    font-size: 1.6rem;
}

.pdf-container {
    width: 100%;
    height: 100%;
}

.pdf-name-box {
    margin-top: -16px;
    width: 100%;
    background-color: #FAFAFA;
}

.pdf-name {
    font-size: 1.6rem;
    font-weight: 600;
    padding: 10px 15px;
}

.pdf-pages-container {
    width: 100%;
    height: calc(100vh - 100px);
    overflow: scroll;
}